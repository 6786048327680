<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
}
</script>

<style>
html,body,#app {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #ffffff;
}
body {
  background-size: contain;
  color: #404040;
  font-family: "ヒラギノ角ゴシック","メイリオ",Meiryo,Osaka,"MS Pゴシック","MS PGothic",sans-serif;
}
</style>

<template>

</template>

<script>
export default {
  name: "Global",
  // host: 'http://localhost:39457',
  // host: 'http://13.112.41.19:39457',
  host: 'https://hope.api.rapha99.com'
}
</script>

<style scoped>

</style>
